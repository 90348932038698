export * from './api-interceptor.service';
import { ApiInterceptorService } from './api-interceptor.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './api-calls-in-progress.service';
import { ApiCallsInProgress } from './api-calls-in-progress.service';
export * from './menu-collapse.service';
import { MenuCollapseService } from './menu-collapse.service';
export * from './current-foundation.service';
import { CurrentFoundationService } from './current-foundation.service';
export * from './modal.service';
import { ModalService } from './modal.service';

export const SHARED_SERVICES = [
  AuthService,
  ApiCallsInProgress,
  MenuCollapseService,
  CurrentFoundationService,
  ModalService
];
