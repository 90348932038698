import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AnnouncementApi {
  protected basePath = `${environment.API_V4}/announcements`;

  constructor(protected http: HttpClient) {}

  /**
   *
   */
  public getAnnouncements(): Observable<models.Announcement[]> {
    const path = this.basePath;
    return this.http.get<models.Announcement[]>(path);
  }

  public updateAnnouncement(data?: models.Announcement): Observable<models.Announcement> {
    const path = `${this.basePath}/${data.id}`;
    return this.http.patch<models.Announcement>(path, data);
  }

  public createAnnouncement(data?: models.Announcement): Observable<models.Announcement> {
    const path = `${this.basePath}`;
    return this.http.post<models.Announcement>(path, data);
  }

  public deleteAnnouncement(announcementId: string) {
    const path = `${this.basePath}/${announcementId}`;
    return this.http.delete<{ success: boolean }>(path);
  }
}
