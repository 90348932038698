import {
  Component,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dash-base',
  templateUrl: './base.component.html'
})
export class BaseComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  ngOnDestroy() {
    this.subscriptions
      .filter(s => s && s.unsubscribe)
      .forEach(s => s.unsubscribe());
  }
}
