import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Foundation } from '../api';

@Injectable()
export class CurrentFoundationService {
  private _foundation?: Foundation;
  private foundation: BehaviorSubject<Foundation> =
    new BehaviorSubject<Foundation>(this._foundation);
  private foundationUpdate: Subject<Foundation> = new Subject<Foundation>();
  private foundationDelete: Subject<Foundation> = new Subject<Foundation>();
  private winnersCount: Subject<number> = new Subject<number>();

  getWinnersCount(): Observable<number> {
    return this.winnersCount.asObservable();
  }

  setWinnersCount(winnersCount: number): void {
    this.winnersCount.next(winnersCount);
  }

  getFoundationUpdate(): Observable<Foundation> {
    return this.foundationUpdate.asObservable();
  }

  setFoundationUpdate(foundation: Foundation): void {
    this.foundationUpdate.next(foundation);
  }

  getFoundationDelete(): Observable<Foundation> {
    return this.foundationDelete.asObservable();
  }

  deleteFoundation(foundation: Foundation): void {
    this.foundationDelete.next(foundation);
  }

  getFoundationObs(): Observable<Foundation> {
    return this.foundation.asObservable();
  }

  getFoundation(): Foundation {
    return this._foundation;
  }
  setFoundation(foundation: Foundation): void {
    this._foundation = foundation;
    this.foundation.next(this._foundation);
  }
}
