import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class ApiCallsInProgress {
  private callsCount = 0;
  private callsSubject: Subject<number> = new Subject<number>();
  getObservable(): Observable<number> {
    return this.callsSubject.asObservable();
  }
  addCall(number = 1) {
    this.callsCount += number;
    this.callsSubject.next(this.callsCount);
  }
  substractCall() {
    this.callsCount --;
    this.callsCount = this.callsCount < 0 ? 0 : this.callsCount;
    this.callsSubject.next(this.callsCount);
  }
  reset() {
    this.callsCount = 0;
    this.callsSubject.next(this.callsCount);
  }

}
