import { Component, OnInit, HostListener } from '@angular/core';
import { ModalService } from '../../services/index';
import { Modal, KEY_CODE } from './modal.classes';

@Component({
  selector: 'dash-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modals: Modal[] = [];
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.modals || !this.modals.length) {return; }
    if (event.keyCode === KEY_CODE.ENTER) {
      if (this.modals && this.modals.length) {
        this.response(this.modals.length - 1, true);
      }
    }
    if (event.keyCode === KEY_CODE.ESCAPE) {
      if (this.modals && this.modals.length) {
        this.response(this.modals.length - 1, false);
      }
    }
  }

  constructor(
    private modalService: ModalService
  ) {
    this.modalService.getModals().subscribe(modals => this.modals = modals);
  }

  ngOnInit() {
  }
  response(index: number, response: boolean) {
    this.modalService.setResponse(index, response);
  }

}
