import { Component, OnInit, Input } from '@angular/core';
import { CurrentFoundationService } from '../../services';
import { Foundation, TeamApi } from '../../api';
import { BaseComponent } from '../base/base.component';
import { version } from '../../../../environments/version';
import { APP_CONFIG } from '../../../config/app';

@Component({
  selector: 'dash-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseComponent implements OnInit {
  winners: number;
  hasTeam: boolean;
  hasVenues = false;
  foundation: Foundation;
  version = version;
  appName = APP_CONFIG.title;
  @Input() isDash: boolean;

  constructor(
    private currentFoundationService: CurrentFoundationService,
    private teamApi: TeamApi
  ) {
    super();
  }

  ngOnInit() {
    const subs = this.currentFoundationService
      .getFoundationObs()
      .subscribe((f) => {
        this.foundation = f;
        this.hasVenues = !!(f && f.venueIds && f.venueIds.length);
        this.loadTeamData();
      });
    this.subscriptions.push(subs);
    const subsW = this.currentFoundationService
      .getWinnersCount()
      .subscribe((count) => (this.winners = count));
    this.subscriptions.push(subsW);
  }
  loadTeamData() {
    this.hasTeam = false;
    if (!this.foundation || !this.foundation.id) {
      return;
    }
    this.teamApi.find(this.foundation.id).subscribe(
      (team) => {
        if (team) {
          this.hasTeam = true;
        }
      },
      () => (this.hasTeam = false)
    );
  }
}
