import { AssetMapper } from './asset.mapper';
import { Team } from '..';

export class TeamMapper {
  public static mapItems(teams: Team[]): Team[] {
    return teams.map(TeamMapper.mapItem);
  }
  public static mapItem(team: Team): Team {
    team.logo = team.logo
      ? AssetMapper.mapItem(team.logo, team.foundationId.toString())
      : null;
    team.imageUrl = team.logo
      ? team.logo.imageUrl
      : 'assets/images/no-image-available.png';
    team.mobileImage = team.mobileImage
      ? AssetMapper.mapItem(team.mobileImage, team.foundationId.toString())
      : null;

    return team;
  }
}
