<div class="header-container ng-scope header-fixed">
  <header class="top-header clearfix">
    <!-- Logo -->
    <div class="logo">
      <a routerLink="/">
        <span class="logo-brand"> V1 💪</span>
      </a>
    </div>

    <!-- needs to be put after logo to make it working-->
    <div
      class="menu-button"
      (click)="menuCollapseService.toggleCollapse(); (false)"
    >
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </div>

    <div class="top-nav">
      <ul class="nav-left list-unstyled">
        <li>
          <a
            href
            (click)="menuCollapseService.toggleCollapse(); (false)"
            class="toggle-min"
            id="step4"
          >
            <i class="fa fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul class="nav-left list-unstyled">
        <li>
          <span class="ui-select he-foundation" *ngIf="isDash">
            <select
              [(ngModel)]="selectededFoundation"
              (ngModelChange)="selectFoundation()"
            >
              <option
                *ngFor="let foundation of foundations"
                [ngValue]="foundation"
              >
                {{ foundation.name }}
              </option>
            </select>
          </span>
        </li>
      </ul>
      <ul class="nav-right pull-right list-unstyled">
        <li>
          <a id="sign-out" href (click)="logout(); (false)">
            <i class="fa fa-sign-out"></i>
            <span>Sign Out</span>
          </a>
        </li>
      </ul>
    </div>
  </header>
</div>
