const APP_PREFIX = 'DASH_ADMIN-';

export enum StorageParameters {
  AccessTokenId = 'AccessTokenId',
  userRole = 'userRole',
  userId = 'userId',
  userEmail = 'userEmail',
  foundationId = 'foundationId',
  lastFoundation = 'lastFoundation'
}

export class StorageDriver {

  static setItem(key: StorageParameters, value: any): void {
    try {
      localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    } catch (e) { }
  }

  static getItem(key: StorageParameters): any {
    let item = null;
    try {
      item = JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
    } catch (e) { }
    return item;
  }

  static removeItem(key: StorageParameters): void {
    try {
      localStorage.removeItem(`${APP_PREFIX}${key}`);
    } catch (e) { }
  }

  static clearItems(): void {
    try {
      localStorage.clear();
    } catch (e) { }
  }
}
