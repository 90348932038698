import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthAccount, AccessToken, UserLogin } from '../model';

@Injectable()
export class UserApi {
  protected basePath = `${environment.API_V4}/users`;

  constructor(protected http: HttpClient) {}

  authenticated() {
    const path = `${this.basePath}/authenticated`;
    return this.http.get<AuthAccount>(path);
  }

  login(credentials: { username?: string; email?: string; password: string }) {
    const path = `${this.basePath}/login`;
    return this.http.post<AccessToken>(path, credentials);
  }

  logout() {
    const path = `${this.basePath}/logout`;
    return this.http.post<AccessToken>(path, {});
  }

  public loginWithGoogle(token: any) {
    const path = `${this.basePath}/login/google`;
    return this.http.post<UserLogin>(path, { token });
  }
}
