import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Foundation, FoundationApi } from '../../api';
import {
  AuthService,
  ApiCallsInProgress,
  MenuCollapseService,
  CurrentFoundationService,
} from '../../services';
import { StorageDriver, StorageParameters } from '../../storage';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'dash-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() isDash: boolean;
  @Input() userFoundationId: string;

  selectededFoundation: Foundation;
  foundations: Foundation[];

  constructor(
    private authService: AuthService,
    private apiCallsInProgress: ApiCallsInProgress,
    public menuCollapseService: MenuCollapseService,
    private foundationApi: FoundationApi,
    private currentFoundationService: CurrentFoundationService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    const subs = this.currentFoundationService
      .getFoundationUpdate()
      .subscribe((foundation) => {
        if (this.foundations && this.foundations.length) {
          const index = this.foundations.findIndex(
            (f) => f.id === foundation.id
          );
          if (index >= 0) {
            this.foundations[index] = Object.assign({}, foundation);
            if (
              this.selectededFoundation &&
              this.selectededFoundation.id === foundation.id
            ) {
              this.selectededFoundation = this.foundations[index];
            }
          } else {
            this.loadFoundations();
          }
        }
      });
    this.subscriptions.push(subs);
    const subsDelete = this.currentFoundationService
      .getFoundationDelete()
      .subscribe((foundation) => {
        if (!this.foundations) return;
        this.foundations = this.foundations.filter(
          (f) => f.id !== foundation.id
        );
      });
    this.subscriptions.push(subsDelete);
    this.loadFoundations();
  }
  loadFoundation() {
    this.apiCallsInProgress.addCall();
    this.foundationApi.findById(this.userFoundationId).subscribe(
      (foundation) => {
        this.apiCallsInProgress.reset();
        this.foundations = [foundation];
        if (!foundation) {
          this.toastrService.error(
            'You have not been assigned to a foundation'
          );
          this.authService.logout();
          return;
        } else {
          this.selectededFoundation = foundation;
          this.currentFoundationService.setFoundation(
            this.selectededFoundation
          );
        }
      },
      (err) => this.apiCallsInProgress.reset()
    );
  }
  loadFoundations() {
    this.apiCallsInProgress.addCall();
    if (!this.isDash) return this.loadFoundation();
    this.foundationApi.find().subscribe(
      (foundations) => {
        this.apiCallsInProgress.reset();
        this.foundations = foundations;
        let foundation: Foundation;
        foundation = this.foundations.find(
          (f) => f.id === this.userFoundationId
        );
        if (!foundation) {
          const lastFoundationId = StorageDriver.getItem(
            StorageParameters.lastFoundation
          );
          foundation = this.foundations.find((f) => f.id === lastFoundationId);
          if (!foundation && this.foundations && this.foundations[0]) {
            foundation = this.foundations[0];
          }
        }
        if (foundation) {
          this.selectededFoundation = foundation;
          this.currentFoundationService.setFoundation(
            this.selectededFoundation
          );
        }
      },
      (err) => this.apiCallsInProgress.reset()
    );
  }
  selectFoundation() {
    StorageDriver.setItem(
      StorageParameters.lastFoundation,
      this.selectededFoundation.id
    );
    this.currentFoundationService.setFoundation(this.selectededFoundation);
  }

  logout() {
    this.apiCallsInProgress.addCall();
    this.authService.logout(() => this.apiCallsInProgress.reset());
  }
}
