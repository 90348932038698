<div class="dash-modal" *ngFor="let modal of modals; let i = index">
  <div class="modal-body" align="center">
    <div class="modal-header">
      <h4 class="modal-title">{{ modal.title }}</h4>
      <p *ngIf="modal.description">
        <br />
        <span [innerHTML]="modal.description"></span>
      </p>
    </div>
    <div class="confirm-container">
      <button class="btn btn-w-md btn-default" (click)="response(i, true)">
        {{ modal.confirmText || 'Confirm' }}
      </button>
      <div class="space"></div>
      <button class="btn btn-w-md btn-default" (click)="response(i, false)">
        {{ modal.cancelText || 'Cancel' }}
      </button>
    </div>
  </div>
</div>
<div class="modal-background" *ngIf="modals && modals.length"></div>
