import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { AuctionMapper } from '../mappers';

@Injectable()
export class FoundationApi {
  protected basePath = `${environment.API_V4}/foundations`;

  constructor(protected http: HttpClient) {}
  /**
   *
   * @summary Create a new instance of the model and persist it into the data source.
   * @param data Model instance data
   */
  public create(data: models.Foundation): Observable<models.Foundation> {
    return this.http.post<models.Foundation>(this.basePath, data);
  }

  /**
   *
   * @summary Find all instances of the model matched by filter from the data source.
   * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string
   */
  public find() {
    return this.http.get<Array<models.Foundation>>(this.basePath);
  }
  public findSortedFoundations() {
    return this.find().pipe(
      map((foundations) => {
        return foundations.sort((a, b) => {
          const x = (a.name || '').toLowerCase();
          const y = (b.name || '').toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      })
    );
  }

  public findById(id: string) {
    return this.http.get<models.Foundation>(`${this.basePath}/${id}`);
  }

  /**
   *
   * @summary Delete a model instance by {{id}} from the data source.
   * @param id Model id
   */
  public deleteById(id: string): Observable<any> {
    const path = `${this.basePath}/${id}`;
    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling foundationDeleteById.'
      );
    }
    return this.http.delete<any>(path);
  }

  /**
   *
   * @summary Patch an existing model instance or insert a new one into the data source.
   * @param data Model instance data
   */
  public patch(
    id: string,
    data?: models.Foundation
  ): Observable<models.Foundation> {
    const path = `${this.basePath}/${id}`;
    return this.http.patch<models.Foundation>(path, data);
  }

  /**
   *
   * @summary Patch an existing model instance or insert a new one into the data source.
   * @param data Model instance data
   */
  public patchContact(
    id: string,
    data?: models.Foundation
  ): Observable<models.Foundation> {
    const path = `${this.basePath}/${id}/contact`;
    return this.http.patch<models.Foundation>(path, data);
  }

  /**
   *
   * @summary Create a new instance of the model and persist it into the data source.
   * @param data Model instance data
   */
  public createAuction(
    foundationId: string,
    data?: models.Auction
  ): Observable<models.Auction> {
    const path = `${this.basePath}/${foundationId}/auctions`;
    return this.http.post<models.Auction>(path, data);
  }

  /**
   *
   * @summary Create a new instance of the model and persist it into the data source.
   * @param data Model instance data
   */
  public auctions(
    foundationId: string,
    options: {
      skip?: string;
      limit?: string;
      order?: string;
      q?: string;
      state?: string | string[];
      items?: 'true';
    } = {}
  ) {
    const params = new HttpParams({ fromObject: options });
    const path = `${this.basePath}/${foundationId}/auctions`;
    return this.http
      .get<models.Auction[]>(path, { params })
      .pipe(map(AuctionMapper.mapItems));
  }

  /**
   *
   * @summary Get a list of user emails for an specific foundation
   * @param id Foundation Id
   */
  public getFoundationEmailUserList(foundationId: string) {
    const path = `${this.basePath}/${foundationId}/email-user-list`;
    return this.http.get<{ email: string }[]>(path);
  }
}
