<dash-header [userFoundationId]="foundationId" [isDash]="isDash" *ngIf="role"></dash-header>
<div class="main-container" [class.body-wide]="bodyWide" [class.nav-collapsed-min]="collapse" [class.on-canvas]="!collapse">
  <dash-sidenav [isDash]="isDash" *ngIf="role"></dash-sidenav>
  <div id="content" class="content-container animate-fade-up">
    <div class="page panel-default">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<dash-loading></dash-loading>
<dash-modal></dash-modal>
