import { BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserLogin, UserApi } from '../api';
import { StorageDriver, StorageParameters } from '../storage';
import { Router } from '@angular/router';
import * as  Raven from 'raven-js';

@Injectable()
export class AuthService {
  private user: UserLogin;
  private subjectUser: BehaviorSubject<UserLogin> = new BehaviorSubject<UserLogin>(null);

  constructor(
    private userApi: UserApi,
    private router: Router
  ) {
    const AccessTokenId = StorageDriver.getItem(StorageParameters.AccessTokenId);
    if (AccessTokenId) {
      this.refreshUser();
    }
  }

  getUserObs(): Observable<UserLogin> {
    return this.subjectUser.asObservable();
  }
  setUser(user?: UserLogin): void {
    this.user = user;
    this.subjectUser.next(user);
    if (user) {
      StorageDriver.setItem(StorageParameters.AccessTokenId, user.AccessTokenId);
      StorageDriver.setItem(StorageParameters.userRole, user.roleName);
      StorageDriver.setItem(StorageParameters.userId, user.id);
      StorageDriver.setItem(StorageParameters.userEmail, user.email);
      StorageDriver.setItem(StorageParameters.foundationId, user.foundationId);
    } else {
      StorageDriver.clearItems();
    }
  }

  getUser(): UserLogin {
    return this.user;
  }

  logout(cb?: () => any) {
    Raven.setUserContext();
    this.userApi.logout().subscribe(
      () => this.onLogoutDone(cb),
      () => this.onLogoutDone(cb)
    );
  }

  loadRole(user?: any, success?: () => any, error?: () => any) {
    // AccessTokenId
    const token = user ? user.id : (this.user ? this.user.AccessTokenId : null);
    if (!token) {
      this.onLogoutDone(error, false);
      return;
    }
    this.userApi.authenticated()
    .subscribe(({roles, id, email, foundationId, loginId}) => {
      if (roles.length) {
        this.setUser({
          id: loginId,
          roleName: roles[0],
          AccessTokenId: token,
          email,
          username: email,
          foundationId
        });
        if (user) {
          this.router.navigate(['']);
        }
        if (success) {
          success();
        }
      } else {
        this.onLogoutDone(error);
      }
    },
    err => {
      this.onLogoutDone(error);
    });
  }

  onLogoutDone(cb: () => any, redirect = true) {
    this.setUser();
    if (cb) {
      cb();
    }
    this.refreshUser();
    if (redirect) {
      this.router.navigate(['/sign']);
    }
  }

  refreshUser() {
    const user: UserLogin = {
      AccessTokenId: StorageDriver.getItem(StorageParameters.AccessTokenId),
      roleName: StorageDriver.getItem(StorageParameters.userRole),
      id: StorageDriver.getItem(StorageParameters.userId),
      email: StorageDriver.getItem(StorageParameters.userEmail),
      foundationId: StorageDriver.getItem(StorageParameters.foundationId)
    };
    Raven.setUserContext({
      email: user.email,
      id: user.id ? user.id.toString() : null
    });
    this.setUser(user);
  }

}
