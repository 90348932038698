export * from './AssetApi';
import { AssetApi } from './AssetApi';
export * from './AuctionApi';
import { AuctionApi } from './AuctionApi';
export * from './AuctionAssetApi';
import { AuctionAssetApi } from './AuctionAssetApi';
export * from './TransactionApi';
import { TransactionApi } from './TransactionApi';
export * from './ConfigApi';
import { ConfigApi } from './ConfigApi';
export * from './ContentModuleApi';
import { ContentModuleApi } from './ContentModuleApi';
export * from './FaveApi';
import { FaveApi } from './FaveApi';
export * from './FoundationApi';
import { FoundationApi } from './FoundationApi';
import { TeamApi } from './TeamApi';
export * from './TeamApi';
export * from './VenueApi';
import { VenueApi } from './VenueApi';
import { FoundationEmailApi } from './FoundationEmailApi';
export * from './FoundationEmailApi';
import { MessagingApi } from './MessagingApi';
export * from './MessagingApi';
import { ContentApi } from './ContentApi';
import { RepeatOffenderApi } from './RepeatOffenderApi';
export * from './ContentApi';
import { UserApi } from './UserApi';
export * from './UserApi';
import { AdminUserApi } from './AdminUserApi';
export * from './AdminUserApi';
import { AgreementApi, FoundationAgreement } from './AgreementApi';
export * from './AgreementApi';
import { AnnouncementApi } from './AnnouncementApi';
export * from './AnnouncementApi';
import { TeamRequestApi } from './TeamRequestApi';
export * from './TeamRequestApi';

export const API_SERVICES = [
  AgreementApi,
  AssetApi,
  AuctionApi,
  AuctionAssetApi,
  AdminUserApi,
  TransactionApi,
  ConfigApi,
  ContentModuleApi,
  FaveApi,
  FoundationApi,
  FoundationEmailApi,
  MessagingApi,
  TeamApi,
  UserApi,
  VenueApi,
  ContentApi,
  RepeatOffenderApi,
  AnnouncementApi,
  TeamRequestApi,
];
