import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as models from '../model';
import { ContentModuleMapper } from '../mappers/content-module.mapper';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ContentModuleApi {
  protected basePath = `${environment.API_V4}/admin/content-modules`;

  constructor(protected http: HttpClient) {}

  /**
   *
   * @summary Create a new instance of the model and persist it into the data source.
   * @param data Model instance data
   */
  public contentModuleCreate(
    data: Partial<models.ContentModule>
  ): Observable<models.ContentModule> {
    return this.http.post<models.ContentModule>(this.basePath, data);
  }

  /**
   *
   * @summary Delete a model instance by {{id}} from the data source.
   * @param id Model id
   */
  public contentModuleDeleteById(id: string): Observable<any> {
    return this.http.delete<void>(`${this.basePath}/${id}`);
  }

  /**
   *
   * @summary Patch a model instance by {{id}} from the data source.
   * @param id Model id
   */
  public contentModulePatchById(
    id: string,
    data: Partial<models.ContentModule>
  ) {
    return this.http.patch<any>(`${this.basePath}/${id}`, data);
  }

  /**
   *
   * @summary Find all instances of the model matched by filter from the data source.
   * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string
   */
  public contentModuleFind(group: string) {
    return this.http
      .get<Array<models.ContentModule>>(`${this.basePath}/group/${group}`)
      .pipe(map(ContentModuleMapper.mapItems));
  }

  /**
   *
   * @summary Find a model instance by {{id}} from the data source.
   * @param id Model id
   */
  public contentModuleFindById(id: string) {
    return this.http
      .get<models.ContentModule>(`${this.basePath}/${id}`)
      .pipe(map(ContentModuleMapper.mapItem));
  }

  /**
   *
   * @summary Find a model instance by {{foundationId}} from the data source.
   * @param id Model id
   */
  public contentModuleFindByFoundationId(foundationId: string) {
    return this.http
      .get<models.ContentModule | models.ContentModule[]>(
        `${this.basePath}/one-by-foundation/${foundationId}`
      )
      .pipe(
        mergeMap((data) => {
          return Array.isArray(data)
            ? of(ContentModuleMapper.mapItems(data as models.ContentModule[]))
            : of(ContentModuleMapper.mapItem(data as models.ContentModule));
        })
      );
  }
}
