import { Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Modal, ModalSettings } from '../layout/modal/modal.classes';

@Injectable()
export class ModalService {
  private _modals: Modal[] = [];
  private modals: BehaviorSubject<Modal[]> = new BehaviorSubject<Modal[]>([]);
  create(settings: ModalSettings): Observable<boolean> {
    const modal = new Modal(settings);
    this._modals.push(modal);
    this.modals.next(this._modals);
    return modal.observable;
  }

  getModals(): Observable<Modal[]> {
    return this.modals.asObservable();
  }
  setResponse(index: number, response: boolean) {
    const modal = this._modals[index];
    modal.setResponse(response);
    this._modals = this._modals.filter(m => m !== modal);
    this.modals.next(this._modals);
  }

}
