import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MenuCollapseService {
  private _collapse = false;
  private collapse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this._collapse
  );

  constructor() {}

  getCollapse(): Observable<boolean> {
    return this.collapse.asObservable();
  }
  setCollapse(collapse: boolean): void {
    this._collapse = collapse;
    this.collapse.next(this._collapse);
  }
  toggleCollapse() {
    this.setCollapse(!this._collapse);
  }
}
