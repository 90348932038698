import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Currency } from '../model/currency';
import { Transaction } from '../model';

export type TransactionsParams = {
  from: string;
  to: string;
  foundationId?: string;
  itemId?: string;
  auctionId?: string;
  email?: string;
  currency: Currency;
  manuallyReconciled?: string;
};

@Injectable()
export class TransactionApi {
  protected basePath = `${environment.API_V4}/admin/transactions`;

  constructor(protected http: HttpClient) {}

  public refund(transactionId: string, amount: number) {
    const path = `${this.basePath}/${transactionId}/refund`;
    return this.http.post(path, { amount });
  }
  public transactions(params: TransactionsParams) {
    return this.http.get<Transaction[]>(this.basePath, { params });
  }
}
