import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UploadResponse } from '../model';

@Injectable()
export class AssetApi {
  protected basePath = `${environment.API_V4}/assets`;

  constructor(protected http: HttpClient) {}

  public upload(container: string, file: any, fileName: string) {
    const path = `${this.basePath}/${container}/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file, fileName);
    return this.http.post<UploadResponse>(path, formData);
  }
}
