import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuctionAsset } from '../model';

@Injectable()
export class AuctionAssetApi {
  protected basePath = `${environment.API_V4}/auction-asset`;

  constructor(protected http: HttpClient) {}

  public create(data?: Partial<AuctionAsset>) {
    return this.http.post<AuctionAsset>(this.basePath, data);
  }
}
