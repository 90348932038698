import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as models from '../model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ContentApi {
  protected basePath = `${environment.API_V4}/content`;

  constructor(protected http: HttpClient) {}

  public leagueContent(league: string) {
    return this.http
      .get<Array<models.ContentSectionResponse>>(`${this.basePath}/team/${league}`);
  }

}
