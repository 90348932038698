import { map } from 'rxjs/operators';
import * as models from '../model';
import { AuctionMapper } from '../mappers';
import { Auction } from '../model';
import { ApiBase } from './api-base';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export type FoundationAgreement = {
  id: string;
  name: string;
  fileKey: string;
  fileType: string;
  isVisible: boolean;
  foundationIds: string[];
  readBy: {
    foundationId: string;
    date: Date;
    userId: string;
  }[];
  readSummary: {
    foundationId: string;
    foundationName: string;
    read: boolean;
    readAt?: string;
    readBy?: string;
  }[];
};

export type CreateFoundationAgreementBody = Pick<
  FoundationAgreement,
  'name' | 'isVisible' | 'foundationIds' | 'fileKey' | 'fileType'
>;

@Injectable()
export class AgreementApi extends ApiBase {
  constructor(http: HttpClient) {
    super(http);
  }
  protected model = 'foundation-agreements';

  public getAllAgreements(foundationId?: string) {
    return this.http.get<FoundationAgreement[]>(this.modelUri(), {
      params: this.setParams({ foundationId }),
    });
  }

  public getDownloadLink(id: string) {
    return this.http.get<{ downloadLink: string }>(
      this.modelUri(id, 'download-link')
    );
  }
  public preUpload(body: { contentType: string; fileExtension: string }) {
    return this.http.post<{
      signedUrl: string;
      fileKey: string;
    }>(this.modelUri('pre-upload'), body);
  }

  public uploadToS3(signedUrl: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http.put(signedUrl, file, { headers, reportProgress: true });
  }

  public create(data: CreateFoundationAgreementBody) {
    return this.http.post<FoundationAgreement>(this.modelUri(), data);
  }

  public delete(id: string) {
    return this.http.delete<{ success: boolean }>(this.modelUri(id));
  }

  public patch(id: string, data: Partial<FoundationAgreement>) {
    return this.http.patch<FoundationAgreement>(this.modelUri(id), data);
  }

  // public overview(params: OverviewParams) {
  //   return this.http.get<AuctionOverview[]>(this.modelUri('overview'), {
  //     params: this.setParams(params),
  //   });
  // }

  // public currentAuctions() {
  //   return this.http
  //     .get<models.Auction[]>(this.modelUri('auctions', 'current'))
  //     .pipe(map(AuctionMapper.mapItems));
  // }
}
