import { ContentModule, ContentModuleStaticItem } from '../index';
import { environment } from '../../../../environments/environment';

export const LINKS_ASSETS_CONTAINER = 'links';

export class ContentModuleMapper {
  public static mapItems(contentModules: ContentModule[]): ContentModule[] {
    return contentModules.map(ContentModuleMapper.mapItem);
  }
  public static mapItem(contentModule: ContentModule): ContentModule {
    if (
      contentModule.params &&
      contentModule.params.items &&
      contentModule.params.items.length
    ) {
      contentModule.params.items = contentModule.params.items.map(
        ContentModuleMapper.mapParamItem
      );
    }
    return contentModule;
  }
  public static mapParamItem(
    item: ContentModuleStaticItem
  ): ContentModuleStaticItem {
    if (item.assetUrl) {
      item.asset = {
        auctionId: LINKS_ASSETS_CONTAINER,
        name: item.assetUrl,
        url: item.assetUrl,
        imageUrl: `${environment.ASSETS_URL}/${item.assetUrl}`,
      };
    }
    if (item.desktopAssetUrl) {
      item.desktopAsset = {
        auctionId: LINKS_ASSETS_CONTAINER,
        name: item.desktopAssetUrl,
        url: item.desktopAssetUrl,
        imageUrl: `${environment.ASSETS_URL}/${item.desktopAssetUrl}`,
      };
    }
    return item;
  }
  public static reverseMapParamItem(
    item: ContentModuleStaticItem
  ): ContentModuleStaticItem {
    const { asset, desktopAsset, ...otherProps } = item;
    return otherProps;
  }
  public static reverseMapItem(contentModule: ContentModule): ContentModule {
    if (
      contentModule.params &&
      contentModule.params.items &&
      contentModule.params.items.length
    ) {
      contentModule.params.items = contentModule.params.items.map(
        ContentModuleMapper.mapParamItem
      );
    }
    if (
      contentModule.params &&
      contentModule.params.items &&
      contentModule.params.items.length
    ) {
      contentModule.params.items = contentModule.params.items.map(
        ContentModuleMapper.reverseMapParamItem
      );
    }
    return contentModule;
  }
}
