import { AuctionAsset } from '.';

export type ItemType = 'auction' | 'item' | 'web' | 'team';

export enum TeamModuleType {
  Featured = 'featured',
  Events = 'events'
}

export interface ContentModuleStaticItem {
  published: boolean;
  itemRef?: string;
  itemType: ItemType;
  title?: string;
  params?: any;
  assetUrl?: string;
  asset?: AuctionAsset;
  desktopAssetUrl?: string;
  desktopAsset?: AuctionAsset;
}

export interface ContentModuleParams {
  items?: ContentModuleStaticItem[];
  isLeagueModule?: boolean;
  leagueModuleType?: LeagueContentModuleType;
  teamModuleType?: TeamModuleType;
}

export enum LeagueContentModuleType {
  Banner = 'banner',
  Teams = 'teams',
  Causes = 'causes',
  Events = 'events'
}

export type ContentModuleType = 'stacked' | 'round' | 'image_round' | 'wrap';

export interface ContentModule {
  id: string;
  title: string;
  createdAt?: Date;
  updatedAt?: Date;
  content: string;
  layout: ContentModuleType;
  published: boolean;
  access: 'everyone' | 'authenticated' | 'foundation' | 'dash';
  group: string;
  order: number;
  params?: ContentModuleParams;
}
