import { version } from './version';

export const environment = {
  production: false,
  ENV_NAME: 'develop',
  API_V4: 'https://dev-api.winwithdash.com/v4',
  SHORT_URL: 'https://d.winwithdash.com',
  ASSETS_URL: 'https://dev-assets.winwithdash.com',
  DASH_WEB: 'https://dev-fans.winwithdash.com/login',
  RAVEN_CONFIG: 'https://98ec5cd7b6774c0ca18578ef8776ed44@sentry.io/1225210',
  version,
  appId: 'dash',
  ADMIN_URL: 'https://dev-admin.winwithdash.com',
};
