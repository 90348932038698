import { environment } from '../../environments/environment';

const appId = environment.appId;

export const APP_CONFIG =
  !appId || appId === 'dash'
    ? {
        title: 'DASH',
        logoUrl: 'assets/icons/apple-icon-1024x1024.png',
        googleClientId:
          '1058188252524-sihhr2p708in37bmodmq7sn2osjnvou2.apps.googleusercontent.com',
        needsCustomDeluxeIntegration: false,
        favesLabel: 'Favorite Teams',
        lockerLabel: 'My Locker',
        enablePushNotifications: true,
      }
    : {
        title: 'DGP',
        logoUrl: 'assets/images/dgp-logo.png',
        googleClientId:
          '864005913654-paklfigda69jsj8ehi9pubspm2v9p55j.apps.googleusercontent.com',
        needsCustomDeluxeIntegration: true,
        favesLabel: 'Favorite Non-Profits',
        lockerLabel: 'My Items',
        enablePushNotifications: false,
      };
