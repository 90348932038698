import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RepeatOffenderApi {
  protected basePath = `${environment.API_V4}/admin/repeat-offender`;

  constructor(protected http: HttpClient) {}

  public repeatOffendersReport() {
    const path = `${this.basePath}/repeat-offenders-report`;
    return this.http.get<Array<string[]>>(path);
  }
}
