import { Observable } from 'rxjs';

export interface ModalSettings {
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
}

export class Modal implements ModalSettings {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  observable: any;
  private observer: any;
  constructor(settings: ModalSettings) {
    Object.assign(this, settings);
    this.observable = Observable.create(observer => (this.observer = observer));
  }
  setResponse(response: boolean) {
    this.observer.next(response);
    this.observer.complete();
  }
}

export enum KEY_CODE {
  ESCAPE = 27,
  ENTER = 13
}
