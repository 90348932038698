import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiCallsInProgress } from '../../services/api-calls-in-progress.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'dash-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends BaseComponent implements OnInit, OnDestroy {
  calls: number;
  constructor(private apiCallsInProgress: ApiCallsInProgress) {
    super();
  }

  ngOnInit() {
    const subs = this.apiCallsInProgress
      .getObservable()
      .subscribe(calls => (this.calls = calls));
    this.subscriptions.push(subs);
  }
}
