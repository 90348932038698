import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamMapper } from '../mappers';
import { environment } from '../../../../environments/environment';
import { Team } from '../model';

@Injectable()
export class TeamApi {
  protected basePath = `${environment.API_V4}/teams`;

  constructor(protected http: HttpClient) {}

  public find(foundationId: string) {
    const path = `${this.basePath}/theme`;
    return this.http
      .get<Team>(path, {
        params: {
          foundationId,
        },
      })
      .pipe(map(TeamMapper.mapItem));
  }

  public patch(data?: Team): Observable<Team> {
    return this.http.patch<Team>(this.basePath, data);
  }

  public byLeague(league: string): Observable<Array<Team>> {
    const path = `${this.basePath}/byLeague/${league}`;
    return this.http.get<Array<Team>>(path);
  }
}
