import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TeamRequestApi {
  protected basePath = `${environment.API_V4}/team-request`;

  constructor(protected http: HttpClient) {}

  public getFanInterestedReport(faveId: string): Observable<Array<string[]>> {
    const path = `${this.basePath}/fave-interested/${faveId}`;
    return this.http.get<Array<string[]>>(path);
  }

}
