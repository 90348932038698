import { map } from 'rxjs/operators';
import * as models from '../model';
import { AuctionMapper } from '../mappers';
import { Auction } from '../model';
import { ApiBase } from './api-base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

type OverviewParams = {
  foundationId?: string;
  q?: string;
  state: string;
  skip?: number;
};
type AuctionStatItem = {
  name: string;
  revenue: string;
  summary: string;
  winner: string;
  bidders: string[];
};
export type AuctionStats = {
  title: string | undefined;
  auctionEnd: Date | undefined;
  createdAt: Date | undefined;
  foundationId: string;
  bidsCount: number;
  purchasesCount: number;
  revenue: number;
  items: AuctionStatItem[];
  bidders: string[];
};
export type AuctionOverview = Auction & { stats: AuctionStats };

@Injectable()
export class AuctionApi extends ApiBase {
  constructor(http: HttpClient) {
    super(http);
  }
  protected model = 'admin/auctions';

  public patch(id: string, data: Partial<Auction>) {
    return this.http.patch<Auction>(this.modelUri(id), data);
  }

  public overview(params: OverviewParams) {
    return this.http.get<AuctionOverview[]>(this.modelUri('overview'), {
      params: this.setParams(params),
    });
  }

  public currentAuctions() {
    return this.http
      .get<models.Auction[]>(this.modelUri('auctions', 'current'))
      .pipe(map(AuctionMapper.mapItems));
  }
}
