import { Component, OnInit } from '@angular/core';
import { AuthService, MenuCollapseService } from './shared/services';
import { Router, ActivationEnd, NavigationError } from '@angular/router';
import { RolesEnum } from './shared/enums/roles.enum';
import * as Raven from 'raven-js';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  role: string | null;
  isDash: boolean;
  foundationId: string;
  bodyWide: boolean;
  collapse: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuCollapseService: MenuCollapseService
  ) {
    this.router.events.subscribe((navigation) => {
      if (navigation instanceof ActivationEnd) {
        this.bodyWide = navigation.snapshot.data['bodyWideClass'];
      } else if (navigation instanceof NavigationError) {
        Raven.captureException(navigation.error);
      }
    });
    this.menuCollapseService
      .getCollapse()
      .subscribe((collapse) => (this.collapse = collapse));
  }
  ngOnInit() {
    this.authService.getUserObs().subscribe((user) => {
      this.role = user ? RolesEnum[user.roleName] : null;
      this.isDash = this.role === RolesEnum.dash;
      this.foundationId =
        user && user.foundationId ? user.foundationId.toString() : null;
    });
    this.authService.loadRole();
  }
}
