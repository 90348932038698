import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { StorageDriver, StorageParameters } from '../storage';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let avoid = req.url.includes('amazonaws.com')
    // Add here exceptions to avoid intercepting the requests
    if (!avoid) {
      const Authorization = StorageDriver.getItem(StorageParameters.AccessTokenId) || '';
      const headers = {};
      if (!req.headers.get('Content-Type')) {
        // headers['Content-Type'] = 'application/json';
      }
      if (Authorization) {
        headers['Authorization'] = Authorization;
      }
      req = req.clone({
        setHeaders: headers
      });
    }
    return next.handle(req);
  }
}
