import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FoundationEmail } from '../model';

@Injectable()
export class FoundationEmailApi {
  protected basePath = `${environment.API_V4}/foundations`;

  constructor(protected http: HttpClient) {}

  public find(foundationId: string) {
    const path = `${this.basePath}/${foundationId}/emails`;
    return this.http.get<Array<FoundationEmail>>(path);
  }

  public create(foundationId: string, data: Partial<FoundationEmail>) {
    const path = `${this.basePath}/${foundationId}/emails`;
    return this.http.post<FoundationEmail>(path, data);
  }

  public deleteById(foundationId: string, id: string) {
    const path = `${this.basePath}/${foundationId}/emails/${id}`;
    return this.http.delete<{ count: number }>(path);
  }
}
