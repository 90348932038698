import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuctionItem, UserBid, UserLogin, UserProfile } from '../model';

export type DeletingUserDetails = {
  userProfile: UserProfile;
  accessTokens: Date[];
  locker: UserBid;
  userFaves: any; //UserFave[],
  devicesCount: number;
  topBidderItems: AuctionItem[];
  unpaidItems: AuctionItem[];
  loginId: string;
};

@Injectable()
export class AdminUserApi {
  protected basePath = `${environment.API_V4}/admin/users`;

  constructor(protected http: HttpClient) {}

  users() {
    return this.http.get<UserLogin[]>(this.basePath);
  }
  findByEmail(email: string) {
    return this.http.get<DeletingUserDetails>(
      `${this.basePath}/user-details?email=${encodeURIComponent(email)}`
    );
  }
  fullDelete(loginId: string, email: string) {
    return this.http.delete<{
      deletedAccessTokens: number;
      deletedUserFaves: number;
      deletedDevicesCount: number;
    }>(
      `${this.basePath}/${loginId}/full-delete?email=${encodeURIComponent(
        email
      )}`
    );
  }

  upsert({ roleName, ...user }: Partial<UserLogin>) {
    if (user.id) {
      const path = `${this.basePath}/${user.id}`;
      return this.http.patch<UserLogin>(path, user, {
        params: { roleName },
      });
    } else {
      return this.http.post<UserLogin>(this.basePath, user, {
        params: { roleName },
      });
    }
  }

  delete(loginId: string) {
    const path = `${this.basePath}/${loginId}`;
    return this.http.delete<void>(path);
  }
}
