import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import isNonNil from '../../helpers/is-non-nil';

export abstract class ApiBase {
  protected readonly apiBase = environment.API_V4;
  protected abstract model: string;
  protected get modelBase() {
    return `${this.apiBase}/${this.model}`;
  }

  constructor(protected http: HttpClient) {}

  protected modelUri(...args: string[]) {
    return `${this.modelBase}/${args.join('/')}`;
  }

  protected uri(...args: string[]) {
    return `${this.apiBase}/${args.join('/')}`;
  }

  protected setParams(paramsTemplate: any) {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (isNonNil(paramsTemplate[key])) {
        const element = paramsTemplate[key];
        params =
          typeof element === 'string'
            ? params.set(key, element)
            : params.set(key, element.toString());
      }
    }
    return params;
  }
}
