import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Message } from '../model';

@Injectable()
export class MessagingApi {
  protected basePath = `${environment.API_V4}/messages`;

  constructor(protected http: HttpClient) {}

  public create(data: Partial<Message>) {
    const path = this.basePath;
    return this.http.post<Message>(path, data);
  }

  public find(filter?: string) {
    const path = this.basePath;
    let params = new HttpParams();
    if (filter) {
      params = params.set('filter', filter);
    }
    return this.http.get<Array<Message>>(path, { params });
  }

  public patch(id: string, data: Partial<Message>) {
    const path = `${this.basePath}/${id}`;
    return this.http.patch<Message>(path, data);
  }

  public delete(id: string) {
    const path = `${this.basePath}/${id}`;
    return this.http.delete<{ count: number }>(path);
  }
}
