import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LAYOUT_COMPONENTS } from './layout';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-img-cropper';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ImageCropperModule],
  declarations: [...LAYOUT_COMPONENTS, ...DIRECTIVES, ...PIPES],
  exports: [...LAYOUT_COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {}
