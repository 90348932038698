import * as models from '.';

export enum MessageState {
  Scheduled = 'scheduled',
  Pending = 'pending',
  Sending = 'sending',
  Error = 'error',
  Sent = 'sent',
}

export interface Message {
  id: string;
  state: MessageState;
  sendPush: boolean;
  sendSMS: boolean;
  title?: string;
  body: string;
  pushBody: string;
  sentAt?: string;
  messageScheduledDate?: string | Date;
  isCampaign: boolean;
  sendToProfileIds?: string[];
  deviceIdsSucceeded?: string[];
  deviceIdsFailed?: string[];
  apnsSent: number;
  apnsFailed: number;
  fcmSent: number;
  fcmFailed: number;
  smsSent: number;
  smsQueued: number;
  smsFailed: number;
  foundationIds?: string[];
  foundations?: models.Foundation[];
  leagues?: string[];
  sports?: string[];
}
