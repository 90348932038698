import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Venue } from '../model';

@Injectable()
export class VenueApi {
  protected basePath = `${environment.API_V4}/venues`;

  constructor(protected http: HttpClient) {}

  /**
   *
   * @summary Create a new instance of the model and persist it into the data source.
   * @param data Model instance data
   */
  public venueCreate(data: Venue) {
    const path = this.basePath;
    return this.http.post<Venue>(path, data);
  }

  /**
   *
   * @summary Delete a model instance by {{id}} from the data source.
   * @param id Model id
   */
  public venueDeleteById(id: string) {
    const path = `${this.basePath}/${id}`;
    return this.http.delete<void>(path);
  }

  /**
   *
   * @summary Find all instances of the model matched by filter from the data source.
   * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string
   */
  public venueFind() {
    const path = this.basePath;
    return this.http.get<Array<Venue>>(path);
  }

  /**
   *
   * @summary Patch an existing model instance or insert a new one into the data source.
   * @param data Model instance data
   */
  public patch(id: string, data: Partial<Venue>) {
    const path = `${this.basePath}/${id}`;
    return this.http.patch<Venue>(path, data);
  }
}
