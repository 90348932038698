<aside id="nav-container" class="nav-container ng-scope nav-fixed nav-vertical">
  <div class="nav-wrapper">
    <ul id="nav" class="nav">
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="admin/foundation-info">
          <i class="fa fa-university"></i>
          <span>Foundation Info</span>
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="admin/default-messages">
          <i class="fa fa-bell"></i>
          <span>Default Messages</span>
        </a>
      </li>
      <li
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="hasTeam"
      >
        <a
          [routerLink]="['settings', 'featured-content', foundation.id]"
          [queryParams]="{ isFoundation: true }"
        >
          <i class="fa fa-home"></i>
          <span>{{ foundation.isLeague ? 'League' : 'Featured' }} Content</span>
        </a>
      </li>

      <ng-template [ngIf]="isDash">
        <li>
          <a class="static">
            <span>{{ appName }}</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/foundations">
            <i class="fa fa-sitemap"></i>
            <span>Foundations</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/users">
            <i class="fa fa-users"></i>
            <span>Users</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="isDash"
        >
          <a routerLink="admin/team-info">
            <i class="fa fa-futbol-o"></i>
            <span>League/Team Page</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/notification-list">
            <i class="fa fa-bell"></i>
            <span>Messaging</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/featured-content/home">
            <i class="fa fa-home"></i>
            <span>Home Content</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/venues">
            <i class="fa fa-map-marker"></i>
            <span>Venues</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/transactions">
            <i class="fa fa-credit-card"></i>
            <span>Transactions</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/overview">
            <i class="fa fa-eye"></i>
            <span>Overview</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/announcements">
            <i class="fa fa-bell"></i>
            <span>Announcements</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/agreements">
            <i class="fa fa-file-text-o"></i>
            <span>Terms of Service</span>
          </a>
        </li>
        <li>
          <a class="static" style="padding: 5px"></a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/faves">
            <i class="fa fa-star"></i>
            <span>Faves</span>
          </a>
        </li>
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a routerLink="settings/config">
            <i class="fa fa-cogs"></i>
            <span>System Configs</span>
          </a>
        </li>
        <li id="version">v{{ version }}</li>
      </ng-template>
    </ul>
  </div>
</aside>
