import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Config } from '../model';

@Injectable()
export class ConfigApi {
  protected basePath = `${environment.API_V4}/config`;

  constructor(protected http: HttpClient) {}

  public find() {
    return this.http.get<Array<Config>>(this.basePath);
  }

  public patch(id: string, data: Config) {
    const path = `${this.basePath}/${id}`;
    return this.http.patch<Config>(path, data);
  }
}
