import * as  Raven from 'raven-js';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

Raven
  .config(environment.RAVEN_CONFIG, {
    release: environment.version
  })
  .install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any) {
    Raven.captureException(err.originalError || err);
    console.error(err);
  }
}
