import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { API_SERVICES } from './shared/api/services';
import { SHARED_SERVICES, ApiInterceptorService } from './shared/services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RavenErrorHandler } from './raven-error-handler';
import { AppRoutingModule } from './app.routes';
import { SharedModule } from './shared/shared.module';
import { ToastrModule, ToastNoAnimationModule } from 'ngx-toastr';
import { GUARDS } from './shared/guards';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    ...API_SERVICES,
    ...SHARED_SERVICES,
    ...GUARDS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
