import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FaveApi {
  protected basePath = `${environment.API_V4}/faves`;

  constructor(protected http: HttpClient) {}

  /**
   *
   */
  public faveList(): Observable<any> {
    const path = this.basePath;
    return this.http.get(path);
  }

  public faveByFoundation(
    foundationId: string
  ): Observable<Array<models.Fave>> {
    const path = this.basePath;
    let params = new HttpParams().append('foundationId', foundationId);
    return this.http.get<Array<models.Fave>>(path, { params });
  }

  public faveUpsertPutFaves(data?: models.Fave): Observable<models.Fave> {
    if (data.sequence) {
      data.sequence = Number(data.sequence);
    }
    const path = `${this.basePath}/${data.id}`;
    return this.http.patch<models.Fave>(path, data);
  }

  public faveUCreateFave(data?: models.Fave): Observable<models.Fave> {
    if (data.sequence) {
      data.sequence = Number(data.sequence);
    }
    const path = this.basePath;
    return this.http.post<models.Fave>(path, data);
  }

  public deleteFave(faveId: string) {
    const path = `${this.basePath}/${faveId}`;
    return this.http.delete<{ success: boolean }>(path);
  }

  public sports(): Observable<string[]> {
    return this.http.get<string[]>(`${this.basePath}/sports`);
  }

  public leagues(): Observable<string[]> {
    return this.http.get<string[]>(`${this.basePath}/leagues`);
  }
}
