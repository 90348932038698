import { Auction } from '../index';
import { AssetMapper } from './asset.mapper';

export class AuctionMapper {
  public static mapItems(auctions: Auction[]): Auction[] {
    return auctions.map(AuctionMapper.mapItem);
  }
  public static mapItem(auction: Auction): Auction {
    auction.assets = AssetMapper.mapItems(auction.assets, auction.id.toString());
    const assets = auction.assets;
    let auctionTimeTitle: string;
    if (auction.auctionState === 'closed') {
      auctionTimeTitle = 'YOUR EVENT HAS COMPLETED';
    } else if (auction.auctionState === 'open') {
      auctionTimeTitle = 'YOUR EVENT IS IN PROGRESS';
    } else if (!auction.auctionStart) {
      auctionTimeTitle = 'YOUR EVENT IS NOT SCHEDULED';
    } else if (auction.auctionStart && !auction.auctionEnd) {
      auctionTimeTitle = 'YOUR EVENT IS SCHEDULED TO BEGIN AT';
    } else {
      auction.auctionTimeTitle = 'YOUR EVENT IS SCHEDULED FOR';
    }
    auction.auctionEndTs = auction.auctionEnd ? new Date(auction.auctionEnd).getTime() : null;
    auction.auctionStartTs = auction.auctionStart ? new Date(auction.auctionStart).getTime() : null;
    auction.auctionTimeTitle = auctionTimeTitle;
    auction.started	= auction.auctionState === 'open';
    auction.ended	= auction.auctionState === 'closed';
    auction.hideFromHome = !!auction.hideFromHome;
    auction.items = auction.items || [];
    auction.startInFuture = new Date(auction.auctionStart) > new Date();
    auction.duration = AuctionMapper.auctionDuration(auction.auctionStart, auction.auctionEnd);
    auction.imageUrl =
      assets && assets.length
        ? assets[0].imageUrl
        : 'assets/images/no-image-available.png';
    return auction;
  }
  public static auctionDuration(start?: Date, end?: Date): string {
    if (!start || !end) {
      return 'Undefined';
    }
    start = new Date(start);
    end = new Date(end);
    const timeDiff = end.valueOf() - start.valueOf();
    let seconds		= Math.floor(timeDiff / 1000);
    let minutes 	= Math.floor(seconds / 60);
    let hours		= Math.floor(minutes / 60);
    const days		= Math.floor(hours / 24);

    let seconds_str, minutes_str, hours_str, days_str;
    seconds = seconds % 60;
    if (seconds === 1) {
      seconds_str	= seconds + ' second';
    } else {
      seconds_str = seconds + ' seconds';
    }
    minutes = minutes % 60;
    if (minutes === 1) {
      minutes_str	= minutes + ' minute';
    } else {
      minutes_str	= minutes + ' minutes';
    }
    hours = hours % 24;
    if (hours === 1) {
      hours_str = hours + ' hour';
    } else {
      hours_str = hours + ' hours';
    }
    if (days === 1) {
      days_str = days + ' day';
    } else {
      days_str = days + ' days';
    }
    let display_str = '';
    if (days > 0) {
      display_str = days_str;
    }
    if (hours > 0) {
      if (display_str.length === 0) {
        display_str = hours_str;
      } else {
        display_str = display_str + ', ' + hours_str;
      }
    }
    if (minutes > 0) {
      if (display_str.length === 0) {
        display_str = minutes_str;
      } else {
        display_str = display_str + ', ' + minutes_str;
      }
    }
    return display_str;
  }
}
